// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-card {
    background-color: var(--evolved-photo-card-bg) !important;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    /* cursor: pointer; */
}

.photo-card>.card-body,
.photo-card>.card-title {
    color: var(--evolved-photo-card-text) !important;
}

/* #judges {
    background-image: url('http://5.78.46.54:3000/static/media/2023_Remote.72e1c44e944b5fe3730a.png');
} */

.photo-card img {
    height: 200px;
    object-fit: cover;
    /* width: 100%; */
}

/* .photo-card .card-body {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
} */`, "",{"version":3,"sources":["webpack://./src/components/Event/PhotoGrid.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,mCAA2B;YAA3B,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;;IAEI,gDAAgD;AACpD;;AAEA;;GAEG;;AAEH;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;;;;;GAKG","sourcesContent":[".photo-card {\n    background-color: var(--evolved-photo-card-bg) !important;\n    backdrop-filter: blur(20px);\n    /* cursor: pointer; */\n}\n\n.photo-card>.card-body,\n.photo-card>.card-title {\n    color: var(--evolved-photo-card-text) !important;\n}\n\n/* #judges {\n    background-image: url('http://5.78.46.54:3000/static/media/2023_Remote.72e1c44e944b5fe3730a.png');\n} */\n\n.photo-card img {\n    height: 200px;\n    object-fit: cover;\n    /* width: 100%; */\n}\n\n/* .photo-card .card-body {\n    min-height: 300px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
