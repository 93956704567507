// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-card {
    transition: transform .8s;
}

.asset-card:hover {
    transform: scale(1.05)
}


.btn-primary {
    background: var(--evolved-earth) !important;
    border-color: var(--evolved-earth) !important;
}

.explore-more-datasets {
    cursor: pointer;
}

.explore-more-datasets:hover {
    color: var(--evolved-earth);
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/Assets.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI;AACJ;;;AAGA;IACI,2CAA2C;IAC3C,6CAA6C;AACjD;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".asset-card {\n    transition: transform .8s;\n}\n\n.asset-card:hover {\n    transform: scale(1.05)\n}\n\n\n.btn-primary {\n    background: var(--evolved-earth) !important;\n    border-color: var(--evolved-earth) !important;\n}\n\n.explore-more-datasets {\n    cursor: pointer;\n}\n\n.explore-more-datasets:hover {\n    color: var(--evolved-earth);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
