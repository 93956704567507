// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events-selector {
    cursor: pointer;
    color: var(--evolved-selection-inactive-01);
    display: block;
    text-align: center;
    padding: 15px 15px 15px 0;
    text-decoration: none;
    font-size: x-large;
}

.events-selector.active {
    cursor: pointer;
    text-decoration: underline;
    color: var(--evolved-foreground-main);
}


.events-image-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    /* Optional: to show a placeholder background */
}

.events-image-wrapper::before {
    content: '';
    display: block;
    padding-top: 56.25%;
    /* 9/16 * 100% for a 16:9 aspect ratio */
}

.events-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Events/Events.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,2CAA2C;IAC3C,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,0BAA0B;IAC1B,qCAAqC;AACzC;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,+CAA+C;AACnD;;AAEA;IACI,WAAW;IACX,cAAc;IACd,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".events-selector {\n    cursor: pointer;\n    color: var(--evolved-selection-inactive-01);\n    display: block;\n    text-align: center;\n    padding: 15px 15px 15px 0;\n    text-decoration: none;\n    font-size: x-large;\n}\n\n.events-selector.active {\n    cursor: pointer;\n    text-decoration: underline;\n    color: var(--evolved-foreground-main);\n}\n\n\n.events-image-wrapper {\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n    /* Optional: to show a placeholder background */\n}\n\n.events-image-wrapper::before {\n    content: '';\n    display: block;\n    padding-top: 56.25%;\n    /* 9/16 * 100% for a 16:9 aspect ratio */\n}\n\n.events-image {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
