// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-radius: 0.375rem;
    overflow: hidden;
}

tbody tr:last-child {
    border-bottom: 6px solid var(--evolved-green);
}`, "",{"version":3,"sources":["webpack://./src/components/Event/Schedule.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":["table {\n    border-radius: 0.375rem;\n    overflow: hidden;\n}\n\ntbody tr:last-child {\n    border-bottom: 6px solid var(--evolved-green);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
