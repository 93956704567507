import { useInView } from '../../utils/fadeIn';

interface ResourceCardProps {
    resource: ResourceInterface;
    index: number;
    cardRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
    truncateDescription: (description: string, wordLimit: number) => string;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ resource, index, cardRefs, truncateDescription }) => {
    const [ref, isInView] = useInView({ threshold: 0.1 });

    return (
        <div className="col-md-6 py-2 fade-in">
            <a href={resource.url} target="_blank" rel="noopener noreferrer">
                {/* <div className={`card h-100 resource-card ${isInView ? 'fade-in' : ''}`} ref={ref} style={{ marginBottom: '20px' }}> */}
                <div className="card h-100 resource-card" style={{ marginBottom: '20px' }}>
                    <div className="row h-100">
                        {/* {resource?.image ? (
                            <>
                                <div className="d-flex flex-column col-md-10">
                                    <div ref={el => cardRefs.current[index] = el} className="card-body">
                                        <h5 className="card-title">{resource.title}</h5>
                                        <p className="card-text">{truncateDescription(resource.description, 33)}</p>
                                        <div className='event-badge'>
                                            {resource.type}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: '0' }} className="d-flex align-items-stretch col-md-2">
                                    <img src={resource.image} className="img-fluid rounded-end" alt={resource.title} style={{ height: '100%', objectFit: 'cover' }} />
                                </div>
                            </>
                        ) : ( */}
                        <div className="col-md-12">
                            <div ref={el => cardRefs.current[index] = el} className="card-body">
                                {resource?.sponsor?.logo ? (<>
                                    <img src={resource.sponsor.logo} className="" alt={resource.title} style={{ height: '40px', width: '40px' }} />
                                </>) : ''
                                }
                                <h5 className="card-title">{resource.title}</h5>
                                <p className="card-text">{truncateDescription(resource.description, 33)}</p>
                                <div className='event-badge'>
                                    {resource.type}
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </a>
        </div>
    );
};

// export default ResourceCard;


// ResourcesPage.tsx
import React, { useState, useRef } from 'react';
import resources from '../../data/resources';
import { ResourceInterface } from '../../reducers/types';
import './Resources.css';
// import ResourceCard from './ResourceCard';
import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';

const ResourcesPage: React.FC = () => {
    const activeResources = resources.filter(resource => resource.active);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResources, setFilteredResources] = useState(activeResources);
    const [selectedType, setSelectedType] = useState('all');
    const resourceTitles = Array.from(new Set(activeResources.map(resource => resource.type)));
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);


    const search = (selectedType_: string, searchTerm_: string) => {
        setFilteredResources(activeResources.filter((resource: ResourceInterface) => {
            const resourceType = resource.type;
            const searchMatch = resource.title.toLowerCase().includes(searchTerm_.toLowerCase()) || resource.description.toLowerCase().includes(searchTerm_.toLowerCase());
            const eventMatch = selectedType_ === 'all' || resourceType === selectedType_;
            return searchMatch && eventMatch;
        }));
    };

    const truncateDescription = (description: string, wordLimit: number) => {
        const words = description.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return description;
    };

    const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
    const title = 'Evolved - Resources'


    return (
        <>

            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>{title}</title>
                {/* <link rel="icon" href={favicon} /> */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image"
                    content={generalShare} />
                <meta property="og:url" content="https://hackathon.bio" />
                <meta name="twitter:card" content={metaDescription} />
                <meta property="og:description"
                    content={metaDescription} />
                <meta property="og:site_name" content={title} />
                <meta name="twitter:image:alt" content={title} />
                <meta name="twitter:site" content="@hackathon.bio" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description"
                    content={metaDescription} />
                <link rel="stylesheet"
                    href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
            </Helmet>
            <div className='filter-controls'>
                <h1>Browse Resources</h1>
                <div className='filter-title'>Explore resources available to us</div>
                <input
                    type="text"
                    placeholder="Search resources..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        search(selectedType, e.target.value);
                    }}
                />
                <select className="form-select" style={{ marginLeft: '20px', width: '150px', display: 'inline' }} onChange={(e) => {
                    setSelectedType(e.target.value);
                    search(e.target.value, searchTerm);
                }}>
                    <option value="all">All Types</option>
                    {resourceTitles.map((title, index) => (
                        <option key={index} value={title}>{title}</option>
                    ))}
                </select>
            </div>

            <div className="container">
                <div className="row">
                    {filteredResources.map((resource, index) => (
                        <ResourceCard
                            key={index}
                            resource={resource}
                            index={index}
                            cardRefs={cardRefs}
                            truncateDescription={truncateDescription}
                        />
                    ))}
                </div>
            </div>
            <div className='footer'></div>
        </>
    );
};

export default ResourcesPage;
