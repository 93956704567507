import { EventInterface } from '../reducers/types';
import faqs from './faqItems'
import people from './people'
import companies from './companies';
import assets from './assets';
import resources from './resources';
import prizesCategories from './prizes'

import { calendars, bannerImages, bannerVideos, secondaryImages } from '../assets';



function range(start: number, end: number) {
    const range = [];
    for (let i = start; i <= end; i++) {
        range.push(i.toString());
    }
    return range;
}

const Events: EventInterface[] = [
    {
        id: "evolved-2024",
        type: 'REMOTE',
        location: 'Virtual',
        // info: 'Participants worldwide will use advanced ML techniques to address key biological challenges, leading to a diverse range of projects and research ideas.',
        blurb: 'Join Lux Capital, Evolutionary Scale & Enveda Bioscience in the second Bio x ML hackathon to drive forward the frontier of science. Build on top of the latest foundation models like ESM-3 (98B parameters) and proprietary datasets.',
        homeBlurb: 'In our second Bio x AI Hackathon, teams worldwide will leverage cutting-edge models like EvolutionaryScale’s ESM3 to shatter scientific limits.',
        date: 'October 10-20, 2024',
        title: 'Evolved - 2024',
        sponsors: companies.filter(company => ['Lux Capital', 'EvolutionaryScale', 'Enveda Biosciences'].includes(company.name)),
        mainImage: bannerImages['evolved-2024'],
        secondaryImage: secondaryImages['evolved-2024'],
        mainVideo: bannerVideos['evolved-2024'],
        upcoming: true,
        faqItems: faqs.filter(faq => range(7, 12).includes(faq.id)),
        prizeCategories: prizesCategories.filter(prizeCategory => range(6, 11).includes(prizeCategory.id)),
        schedule: calendars['evolved-2024'],
        //judges: people.filter(person => ['TBA', 'Tess van Stekelenburg', 'Christoph A. Krettler'].includes(person.name)),
        //speakers: people.filter(person => ['TBA', 'David Healey'].includes(person.name)),
        gettingStarted: `<ol type="I">
        <li>Assemble a team of 5-15 of your favorite people & join our Discord to brainstorm.</li>
        <i>Don’t have a full team? Find new teammates in our #team-up channel.</i>
        <li>Submit an official project proposal <a class='apply-link' href='https://s1nas4re5py.typeform.com/to/dUNvqljy'>here</a> by Septembner 2nd 11:59pm PDT. Projects can be on any relevant topic.</li>
        <i>To help you get started, we’ve included a list of interesting problems in the space below.</i>
        </br>
        <i> We’ve also curated some open datasets and pre-trained foundation models (including EvolutionaryScale’s new ESM3!).</i>
        <li>The top 15-20 teams (finalists) will be selected to participate based on impact, originality, and feasibility.</li>
        <li>10 days of hacking. You’re welcome to use any open dataset or model, including the ones found below in <a class='apply-link' href='#assets'>Assets</a>.</li>
        <li>Finalists will prep a pre-recorded demo and live 5 min pitch. Pitches will be judged over Zoom on Sep 22. Prizes given to the top 3.</li>
        </ol>`,
        // gettingStarted: secondaryImages['evolved-2024'],
        currentAssets: assets.filter(asset => [6, 10, 11, 15, 16, 18, 23, 24, 25, 28, 29, 19, 17].includes(asset.id)),
        currentResources: resources.filter(resource => [5, 9, 11, 12, 13, 14, 15, 16, 17].includes(resource.id)),
        speakersIntro: '',
        prizesIntro: '',//`More coming soon! Prizes will be awarded to the top 3 teams.`,
        judgesIntro: `Coming soon! Past judges have included OpenAI technical staff, ML engineers from HuggingFace and OpenBioML, and the former Chief AI Officer at AbSci.`,

    }, {
        id: "0",
        type: 'REMOTE',
        location: 'Virtual',
        // info: 'Our 2023 online event had united machine learning and biology enthusiasts to collaboratively develop innovative solutions in bioinformatics, personalized medicine, and ecosystem modeling. ',
        blurb: 'Our 2023 online event had united machine learning and biology enthusiasts to collaboratively develop innovative solutions in bioinformatics, personalized medicine, and ecosystem modeling.',
        homeBlurb: 'This inaugural Bio x AI Hackathon united machine learning and biology enthusiasts for 7 days of LLM fine-tuning, backed by major bio communities and tech giants.',
        date: 'June 10th, 2023',
        title: 'Evolved - 2023',
        sponsors: companies.filter(company => ['Lux Capital', 'Hugging Face', 'OpenBioML'].includes(company.name)),
        mainImage: bannerImages['evolved-2023'],
        secondaryImage: secondaryImages['evolved-2023'],
        // mainVideo: Video23,
        mainVideo: bannerVideos['evolved-2023'],
        upcoming: false,
        faqItems: faqs.filter(faq => range(1, 6).includes(faq.id)),
        judges: people.filter(person => range(1, 9).includes(person.id)),
        prizeCategories: prizesCategories.filter(prizeCategory => range(1, 5).includes(prizeCategory.id)),
        schedule: calendars['evolved-2023'],
        currentResources: resources.filter(resource => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(resource.id)),
        speakersIntro: '',
        prizesIntro: 'We are thrilled to announce that we have secured incredible prices from our sponsors! They have generously offered fantastic deals, ensuring we can provide even more value and opportunities to our community.',
        judgesIntro: 'Our hackathon boasts a panel of awesome judges who bring a wealth of experience and expertise to meticulously assess and evaluate the ingenuity of your solutions!',
    },
];


export default Events;


// {
//     id: "1",
//     type: 'IN PERSON',
//     location: 'NYC',
//     info: 'Held in New York City, this 2023 in-person event had created a dynamic environment for hands-on collaboration and networking among machine learning and biology experts. ',
//     date: 'October 24th, 2023',
//     title: 'Evolved - NYC',
//     sponsors: companies.filter(company => ['1', '2', '3', '4'].includes(company.id)),
//     mainImage: bannerImages['nyc-2023'],
//     secondaryImage: secondaryImages['nyc-2023'],
//     mainVideo: bannerVideos['nyc-2023'],
//     upcoming: false,
//     faqItems: faqs.filter(faq => ['1', '2', '3', '4', '5', '6'].includes(faq.id)),
//     judges: people.filter(person => ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(person.id)),
//     prizeCategories: prizesCategories.filter(prizeCategory => ['1', '2', '3', '4', '5'].includes(prizeCategory.id)),
//     schedule: evolved2023schedule
// },