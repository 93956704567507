import React from 'react';
import { FaqInterface } from '../../reducers/types';
import './Faq.css'
import Accordion from 'react-bootstrap/Accordion';

interface CollapsibleFAQProps {
  faq: FaqInterface;
}


const CollapsibleFAQ: React.FC<CollapsibleFAQProps> = ({ faq }) => {

  const formatTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Accordion.Header>{faq.q}</Accordion.Header>
      <Accordion.Body>
        {formatTextWithLineBreaks(faq.a)}
      </Accordion.Body>
    </>
  );
};

interface faqList {
  faqs: FaqInterface[];
}

const FaqList: React.FC<faqList> = ({ faqs }) => {
  return (
    <div className="container">
      <Accordion defaultActiveKey="0">
        {faqs.map(faq => (
          <Accordion.Item eventKey={faq.id}>
            <CollapsibleFAQ faq={faq} />
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default FaqList;