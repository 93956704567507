// src/components/ResourcesSnippet.tsx
import React, { useState, useEffect, useRef } from 'react';
import './Resources.css';
import { ResourceInterface } from '../../reducers/types';
import { useNavigate } from 'react-router-dom';


interface ResourcesSnippetProps {
    resources: ResourceInterface[];
}

const ResourcesSnippet: React.FC<ResourcesSnippetProps> = ({ resources }) => {
    const [filteredResources, setFilteredResources] = useState<ResourceInterface[]>([]);
    const [selectedType, setSelectedType] = useState<string | null>('Compute');
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [imageHeights, setImageHeights] = useState<number[]>([]);
    const navigate = useNavigate();
    const resourceTitles = Array.from(new Set(resources.map(resource => resource.type)));

    const filterResources = (selectedType_: string | null) => {
        setFilteredResources(resources.filter((resource: ResourceInterface) => {
            const resourceType = resource.type;
            return selectedType_ === null || resourceType === selectedType_;
        }));
    };

    const updateImageHeights = () => {
        const heights: number[] = filteredResources.map((_, index) => {
            const card = cardRefs.current[index];
            return card ? card.offsetHeight : 200; // Default height as a fallback
        });
        setImageHeights(heights);
    };

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, filteredResources.length);
        updateImageHeights();
        const handleResize = () => {
            updateImageHeights();
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [filteredResources]);

    useEffect(() => {
        filterResources(selectedType);
    }, [selectedType]);

    // Apply initial filter on component mount
    useEffect(() => {
        setSelectedType('Compute');
        filterResources('Compute');
    }, []);



    return (
        <>
            <div className='filter-controls'>

                <div className="btn-group" role="group" aria-label="Filter Buttons" style={{ marginLeft: '20px' }}>
                    {resourceTitles.map((title, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${selectedType === title ? 'btn-primary' : 'btn-secondary'}`}
                            onClick={() => { setSelectedType(title); }}
                        >
                            {title}
                        </button>
                    ))}
                </div>
            </div>

            <div className="container">
                <div className="row">
                    {filteredResources.map((preview, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
                            <a href={preview.url} target="_blank" rel="noopener noreferrer">
                                <div className="card h-100 resource-card" ref={el => cardRefs.current[index] = el}>
                                    <div className="card-body">
                                        {preview?.sponsor?.logo ? (<>
                                            <img src={preview.sponsor.logo} className="" alt={preview.title} style={{ height: '40px', width: '40px' }} />
                                        </>) : ''
                                        }
                                        <h5 className="card-title">{preview.title}</h5>
                                        <p className="card-text">{preview.description}</p>
                                        <div className='event-badge'>
                                            {preview.type}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ResourcesSnippet;
