import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Header.css";
import logo from '../../assets/main/bio-x-ml.svg';
import hamburgerIcon from '../../assets/icons/hamburger-icon.svg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  return (
    <div style={location.pathname === '/' ? {} : { marginBottom: '100px' }}>
      <header className="navbar container" >
        <NavLink id='header-container' onClick={() => setIsActive(false)} to="/"
          style={location.pathname === '/' ? { visibility: 'hidden' } : {}}>
          <img className='header-logo' width='60px' src={logo} alt="Logo" />
        </NavLink>


        <div className='button' id='ctab' style={location.pathname === '/' ? { visibility: 'hidden' } : {}}>
          <a href='https://discord.gg/kTEq6t99CK'>Get Started in Discord</a>
        </div>
      </header>

    </div>
  );

};



export default Header;

