// src/components/AssetsSnippet.tsx
import React, { useState, useEffect, useRef } from 'react';
import './Assets.css';
import { AssetInterface } from '../../reducers/types';



interface AssetsSnippetProps {
    assets: AssetInterface[];
}

const AssetsSnippet: React.FC<AssetsSnippetProps> = ({ assets }) => {
    const [filteredAssets, setFilteredAssets] = useState<AssetInterface[]>([]);
    const [selectedType, setSelectedType] = useState<string | null>('Model');
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [imageHeights, setImageHeights] = useState<number[]>([]);

    const assetTitles = Array.from(new Set(assets.map(asset => asset.type)));

    const filterAssets = (selectedType_: string | null) => {
        setFilteredAssets(assets.filter((asset: AssetInterface) => {
            const assetType = asset.type;
            return selectedType_ === null || assetType === selectedType_;
        }));
    };

    const updateImageHeights = () => {
        const heights: number[] = filteredAssets.map((_, index) => {
            const card = cardRefs.current[index];
            return card ? card.offsetHeight : 200; // Default height as a fallback
        });
        setImageHeights(heights);
    };

    useEffect(() => {
        cardRefs.current = cardRefs.current.slice(0, filteredAssets.length);
        updateImageHeights();
        const handleResize = () => {
            updateImageHeights();
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [filteredAssets]);

    useEffect(() => {
        filterAssets(selectedType);
    }, [selectedType]);

    // Apply initial filter on component mount
    useEffect(() => {
        setSelectedType('Highlight');
        filterAssets('Highlight');
    }, []);


    return (
        <>
            <div className='filter-controls'>

                <div className="btn-group" role="group" aria-label="Filter Buttons" style={{ marginLeft: '20px' }}>
                    {[...assetTitles]
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        }).map((title, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn ${selectedType === title ? 'btn-primary' : 'btn-secondary'}`}
                                onClick={() => { setSelectedType(title); }}
                            >
                                {title}s
                            </button>
                        ))}
                </div>
            </div>

            <div className="container">
                <div className="row">
                    {filteredAssets.map((preview, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-4 mb-4">
                            <a href={preview.url} target="_blank" rel="noopener noreferrer">
                                <div className="card h-100 asset-card" ref={el => cardRefs.current[index] = el}>
                                    {preview?.image ? (<>
                                        <img src={preview.image} className="card-img-top" alt={preview.title} style={{ maxHeight: '200px', objectFit: 'cover' }} />
                                    </>) : ''
                                    }
                                    <div className="card-body">
                                        <h5 className="card-title">{preview.title}</h5>
                                        <p className="card-text">{preview.description}</p>
                                        <div className='event-badge'>
                                            {preview.type}
                                        </div>
                                        {preview?.secondaryType ? (
                                            <div className='event-badge-secondary'>
                                                {preview.secondaryType}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AssetsSnippet;

