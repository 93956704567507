import { PeopleInterface } from '../reducers/types';

import { peopleImages } from '../assets';

const people: PeopleInterface[] = [
    {
        id: "0",
        name: 'TBA',
        image: peopleImages['tba'],
        affiliation: '',
        bio: `Our exciting panel of experts will be announced soon. Stay tuned for updates!`,
    },
    {
        id: "1",
        name: 'Chelsea Voss',
        image: peopleImages['chelsea-voss'],
        affiliation: 'OpenAi',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "2",
        name: 'Michael Retchin',
        image: peopleImages['michael-retchin'],
        affiliation: 'Nucleate',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "3",
        name: 'Tess van Stekelenburg',
        image: peopleImages['tess-van-stekelenburg'],
        affiliation: 'Lux Capital',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "4",
        name: 'Niccolo Zanchelli',
        image: peopleImages['niccolo-zanchelli'],
        affiliation: 'OpenBioML',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "5",
        name: 'Katie Link',
        image: peopleImages['katie-link'],
        affiliation: 'HuggingFace',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "6",
        name: 'Alfredo Andere',
        image: peopleImages['alfredo-andere'],
        affiliation: 'LatchBio',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "7",
        name: 'Anton Troynikov',
        image: peopleImages['anton-troynikov'],
        affiliation: 'Chroma',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "8",
        name: 'Lan Jiang',
        image: peopleImages['lang-jiang'],
        affiliation: 'Lux Capital',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "9",
        name: 'Joshua Meier',
        image: peopleImages['joshua-meier'],
        affiliation: 'AbSci',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "10",
        name: 'Oren Kraus',
        image: peopleImages['oren-kraus'],
        affiliation: 'Recursion Pharmaceuticals',
        bio: `Recursion Foundation Model`,
    }, {
        id: "11",
        name: 'David Healey',
        image: peopleImages['david-healey'],
        affiliation: 'Enveda Biosciencies',
        bio: `Enveda Foundation Model`,
    }, {
        id: "12",
        name: 'Christoph A. Krettler',
        image: peopleImages['christoph-krettler'],
        affiliation: 'Enveda Biosciencies',
        bio: ``,
    }]


export default people;