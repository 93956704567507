import React, { useEffect, useState } from 'react';
import './Schedule.css';
import { format, parseISO } from 'date-fns';
import { toZonedTime, format as formatTz, fromZonedTime } from 'date-fns-tz';
const ical = require('cal-parser');

interface ICalendarEvent {
    description: { value: string };
    dtend: { value: string };
    dtstamp: { value: string };
    dtstart: { value: string };
    location: { value: string };
    summary: { value: string };
    uid: { value: string };
}

interface ICalendarData {
    calscale: string;
    prodid: string;
    version: string;
    timezone: {
        tzid: string;
        'last-modified': string;
        tzurl: string;
    };
    events: ICalendarEvent[];
}

interface EventsTableProps {
    eventsString: string;
}

const addHours = (date: Date, hours: number): Date => {
    const newDate = new Date(date.getTime());
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
};

const getReadableTimezone = (input: string): string => {
    const parts = input.split('/');
    if (parts.length < 2) {
        throw new Error('Input string does not contain a /');
    }
    const secondPart = parts[1];
    return secondPart.replace(/_/g, ' ');
};

const ScheduleTable: React.FC<EventsTableProps> = ({ eventsString }) => {
    const [calendar, setCalendar] = useState<ICalendarData | null>(null);
    const [userTimeZone, setUserTimeZone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [userLocale, setUserLocale] = useState<string>(Intl.DateTimeFormat().resolvedOptions().locale);
    // const [userTimeZone, setUserTimeZone] = useState<string>('Europe/Berlin');

    useEffect(() => {
        fetch(eventsString) // Assuming your .ics file is at public/events.ics
            .then(response => response.text())
            .then(data => {
                setCalendar(ical.parseString(data));
            })
            .catch(error => {
                console.error('Error fetching .ics file:', error);
            });
    }, [eventsString]);

    const formatTime = (date: Date) => {
        const isUSLocale = userLocale.startsWith('en-US');
        return date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit', hour12: isUSLocale });
    };

    return (calendar ? (
        <>
            Times shown in {getReadableTimezone(userTimeZone)} time.

            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        {/* <th>From</th>
                        <th>To</th> */}
                        <th>Time</th>
                        <th>Description</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {calendar.events.map((event, index) => {
                        const dtStartUtc = addHours(fromZonedTime(event.dtstart.value, 'America/Los_Angeles'), 4);
                        const dtEndUtc = addHours(fromZonedTime(event.dtend.value, 'America/Los_Angeles'), 4);

                        const dtStartZoned = toZonedTime(dtStartUtc, userTimeZone);
                        const dtEndZoned = toZonedTime(dtEndUtc, userTimeZone);


                        return (

                            <tr key={index}>
                                <td>{formatTz(dtStartZoned, 'yyyy-MMM-dd', { timeZone: userTimeZone })}</td>
                                <td>{formatTime(dtStartZoned)}</td>
                                {/* <td>{formatTime(dtEndZoned)}</td> */}
                                <td>{event.description.value}</td>
                                <td>{event.location.value}</td>

                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <a href={eventsString} download='evolved-hackathon.ics' className="button">
                Download Calendar
            </a>
            {/* <a href={eventsString} download='evolved-2023.ics' className="button">
                Copy Calendar URL
            </a> */}
        </>
    ) : null);
};

export default ScheduleTable;
