import { useInView } from '../../utils/fadeIn';

interface AssetCardProps {
    asset: AssetInterface;
    index: number;
    cardRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
    truncateDescription: (description: string, wordLimit: number) => string;
}

const AssetCard: React.FC<AssetCardProps> = ({ asset, index, cardRefs, truncateDescription }) => {
    const [ref, isInView] = useInView({ threshold: 0.1 });

    return (
        <div className="col-md-6 py-2 fade-in">
            <a href={asset.url === '/assets' ? undefined : asset.url} target="_blank" rel="noopener noreferrer">
                {/* <div className={`card h-100 asset-card ${isInView ? 'fade-in' : ''}`} ref={ref} style={{ marginBottom: '20px' }}> */}
                <div className="card h-100 asset-card" style={{ marginBottom: '20px' }}>
                    <div className="row h-100">
                        {/* {asset?.image ? (
                            <>
                                <div className="d-flex flex-column col-md-10">
                                    <div ref={el => cardRefs.current[index] = el} className="card-body">
                                        <h5 className="card-title">{asset.title}</h5>
                                        <p className="card-text">{truncateDescription(asset.description, 33)}</p>
                                        <div className='event-badge'>
                                            {asset.type}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: '0' }} className="d-flex align-items-stretch col-md-2">
                                    <img src={asset.image} className="img-fluid rounded-end" alt={asset.title} style={{ height: '100%', objectFit: 'cover' }} />
                                </div>
                            </>
                        ) : ( */}
                        <div className="col-md-12">
                            <div ref={el => cardRefs.current[index] = el} className="card-body">
                                <h5 className="card-title">{asset.title}</h5>
                                <p className="card-text">{truncateDescription(asset.description, 33)}</p>
                                <div className='event-badge'>
                                    {asset.type}
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </a>
        </div>
    );
};

// export default AssetCard;


// AssetsPage.tsx
import React, { useState, useRef } from 'react';
import assets from '../../data/assets';
import { AssetInterface } from '../../reducers/types';
import './Assets.css';
// import AssetCard from './AssetCard';

import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';

const AssetsPage: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    // const [filteredAssets, setFilteredAssets] = useState(assets);

    const [filteredAssets, setFilteredAssets] = useState(() => {
        const sortedAssets = [...assets].sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
        });
        return sortedAssets;
    });

    const [selectedType, setSelectedType] = useState('all');
    const resourceTitles = Array.from(new Set(assets.map(asset => asset.type)));
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

    const search = (selectedType_: string, searchTerm_: string) => {
        setFilteredAssets(assets.filter((asset: AssetInterface) => {
            const resourceType = asset.type;
            const searchMatch = asset.title.toLowerCase().includes(searchTerm_.toLowerCase()) || asset.description.toLowerCase().includes(searchTerm_.toLowerCase());
            const eventMatch = selectedType_ === 'all' || resourceType === selectedType_;
            return searchMatch && eventMatch;
        }));
    };

    const truncateDescription = (description: string, wordLimit: number) => {
        const words = description.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return description;
    };

    const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
    const title = 'Evolved - Assets'

    return (
        <>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>{title}</title>
                {/* <link rel="icon" href={favicon} /> */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image"
                    content={generalShare} />
                <meta property="og:url" content="https://hackathon.bio" />
                <meta name="twitter:card" content={metaDescription} />
                <meta property="og:description"
                    content={metaDescription} />
                <meta property="og:site_name" content={title} />
                <meta name="twitter:image:alt" content={title} />
                <meta name="twitter:site" content="@hackathon.bio" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description"
                    content={metaDescription} />
                <link rel="stylesheet"
                    href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
            </Helmet>
            <div className='filter-controls'>
                <h1>Browse Assets</h1>
                <div className='filter-title'>Explore a curated selection of valuable assets</div>
                <input
                    type="text"
                    placeholder="Search assets..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        search(selectedType, e.target.value);
                    }}
                />
                <select className="form-select" style={{ marginLeft: '20px', width: '150px', display: 'inline' }} onChange={(e) => {
                    setSelectedType(e.target.value);
                    search(e.target.value, searchTerm);
                }}>
                    <option value="all">All Types</option>
                    {resourceTitles.map((title, index) => (
                        <option key={index} value={title}>{title}</option>
                    ))}
                </select>
            </div>

            <div className="container">
                <div className="row">
                    {filteredAssets.map((asset, index) => (
                        <AssetCard
                            key={index}
                            asset={asset}
                            index={index}
                            cardRefs={cardRefs}
                            truncateDescription={truncateDescription}
                        />
                    ))}
                </div>
            </div>
            <div className='footer'></div>
        </>
    );
};

export default AssetsPage;
