// src/assets.ts
import { loadAssets } from './utils/loadAssets';

const logosContext = require.context('./assets/images/company-logos', true, /\.(png|webp|svg|jpeg|jpg)$/);
const peopleImagesContext = require.context('./assets/images/people', true, /\.(png|webp|svg|jpeg|jpg)$/);
const showcaseImagesContext = require.context('./assets/images/showcases', true, /\.(png|webp|svg|jpeg|jpg)$/);
const showcaseVideosContext = require.context('./assets/videos/showcases', true, /\.(mp4)$/);
const assetsImagesContext = require.context('./assets/images/assets', true, /\.(png|webp|svg|jpeg|jpg)$/);
const calendarContext = require.context('./assets/calendars', true, /\.(ics)$/);
const bannerImagesContext = require.context('./assets/images/banners', true, /\.(png|webp|svg|jpeg|jpg)$/);
const bannerVideosContext = require.context('./assets/videos/banners', true, /\.(mp4)$/);
const heroImageContext = require.context('./assets/images/hero', true, /\.(png|webp|svg|jpeg|jpg)$/);
const secondaryImagesContext = require.context('./assets/images/event-secondaries', true, /\.(png|webp|svg|jpeg|jpg)$/);
const iconsContext = require.context('./assets/icons', true, /\.(png|webp|svg|jpeg|jpg)$/);

export const logos = loadAssets(logosContext);
export const peopleImages = loadAssets(peopleImagesContext);
export const showcaseImages = loadAssets(showcaseImagesContext);
export const showcaseVideos = loadAssets(showcaseVideosContext);
export const assetsImages = loadAssets(assetsImagesContext);
export const calendars = loadAssets(calendarContext);
export const bannerImages = loadAssets(bannerImagesContext);
export const bannerVideos = loadAssets(bannerVideosContext);
export const heroImage = loadAssets(heroImageContext);
export const secondaryImages = loadAssets(secondaryImagesContext);
export const icons = loadAssets(iconsContext);