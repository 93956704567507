// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .title-section {
    color: #333; 
    margin-left:15%; 
} */

/* .secondary-navbar {
    overflow: hidden;
    display: flex;

}

.secondary-navbar a {
    display: block;
    color: black;
    text-align: center;
    padding: 15px 15px 15px 0;
    text-decoration: none;
    font-size: 17px;

}

.secondary-navbar a:last-child {
    border-right: none;
}

.secondary-navbar a:hover {
    color: black;
} */

.event-image {
    width: 100%;
}

.sponsor-logo {
    height: 100px;
}

.project-wrapper iframe,
.project-wrapper video {
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: block;
    aspect-ratio: 16 / 9;
}

.project-wrapper iframe {
    width: 100%;
    height: 100%;
    background: white;
}

/* .project-wrapper {
    width: 200%;
    height: 200%;
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: top left;
} */

.project-price {
    margin-top: 20px;
    font-size: large;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Project/Project.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;;;;;;;;;;;;;;;;;;;;;;GAsBG;;AAEH;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,mBAAmB;IACnB,6EAA6E;IAC7E,cAAc;IACd,WAAW;IACX,YAAY;IACZ,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;;;;;;GAMG;;AAEH;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["/* .title-section {\n    color: #333; \n    margin-left:15%; \n} */\n\n/* .secondary-navbar {\n    overflow: hidden;\n    display: flex;\n\n}\n\n.secondary-navbar a {\n    display: block;\n    color: black;\n    text-align: center;\n    padding: 15px 15px 15px 0;\n    text-decoration: none;\n    font-size: 17px;\n\n}\n\n.secondary-navbar a:last-child {\n    border-right: none;\n}\n\n.secondary-navbar a:hover {\n    color: black;\n} */\n\n.event-image {\n    width: 100%;\n}\n\n.sponsor-logo {\n    height: 100px;\n}\n\n.project-wrapper iframe,\n.project-wrapper video {\n    border-radius: 20px;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);\n    margin: 0 auto;\n    width: 100%;\n    height: auto;\n    display: block;\n    aspect-ratio: 16 / 9;\n}\n\n.project-wrapper iframe {\n    width: 100%;\n    height: 100%;\n    background: white;\n}\n\n/* .project-wrapper {\n    width: 200%;\n    height: 200%;\n    overflow: hidden;\n    transform: scale(0.5);\n    transform-origin: top left;\n} */\n\n.project-price {\n    margin-top: 20px;\n    font-size: large;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
