import { ProjectInterface } from '../reducers/types';
import { showcaseImages, showcaseVideos } from '../assets';


import prizesCategories from './prizes';
import events from './events'

const Projects: ProjectInterface[] = [
    {
        id: "1",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'SVM',
        demo_link: 'https://bioml-svm-svm.hf.space',
        info: 'Generate unified protein embedding across multiple protein modalities.',
        mainImage: showcaseImages['svm'],
        mainVideo: showcaseVideos['svm'],
        iframe: false,
        prize: prizesCategories.filter(prizeCategory => ['1'].includes(prizeCategory.id))[0],
    },
    {
        id: "2",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Life Language Model',
        demo_link: 'https://github.com/dimenwarper/gexpfoundation_hackathon',
        info: 'Fine-tune large language models for single cell biology on smaller datasets like DepMap.',
        mainImage: showcaseImages['life-language-model'],
        mainVideo: showcaseVideos['life-language-model'],
        iframe: false,
    },
    {
        id: "3",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Resurrection Squad',
        demo_link: 'https://stellular-kangaroo-fec209.netlify.app/',
        info: 'Resurrect old codebases by automatically creating working dev environments.',
        mainImage: showcaseImages['resurrection-squad'],
        mainVideo: showcaseVideos['resurrection-squad'],
        iframe: true,
    },
    {
        id: "4",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'MISATO',
        demo_link: 'https://misato-dataset-qm-property-calculation.hf.space',
        info: 'Predicting protein adaptability using GNN and LLM models trained on the MISATO dataset',
        mainImage: showcaseImages['misato'],
        mainVideo: showcaseVideos['misato'],
        iframe: true,
        prize: prizesCategories.filter(prizeCategory => ['2'].includes(prizeCategory.id))[0],
    },
    {
        id: "5",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Sequence Diffusion',
        demo_link: 'https://merle-protein-generator.hf.space',
        info: 'Sample new proteins from an enzyme family through diffusion models for proteins.',
        mainImage: showcaseImages['sequence-diffusion'],
        mainVideo: showcaseVideos['sequence-diffusion'],
        iframe: true,
        prize: prizesCategories.filter(prizeCategory => ['3'].includes(prizeCategory.id))[0],
    },
    {
        id: "6",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Metrix',
        demo_link: 'https://dnastory.github.io/metrix/',
        info: 'Explore your personal DNA data in an accessible and private format.',
        mainImage: showcaseImages['metrix'],
        mainVideo: showcaseVideos['metrix'],
        iframe: true,
        prize: prizesCategories.filter(prizeCategory => ['4'].includes(prizeCategory.id))[0],
    },
    {
        id: "7",
        event: events.filter(event => ['0'].includes(event.id))[0],
        title: 'Suri',
        demo_link: 'https://huggingface.co/account18hackathon/Suri',
        info: 'Developing a new pipeline to make training large models more efficient.',
        mainImage: showcaseImages['suri'],
        mainVideo: showcaseVideos['suri'],
        iframe: false,
        prize: prizesCategories.filter(prizeCategory => ['5'].includes(prizeCategory.id))[0],
    },

];


export default Projects;