import React from 'react';
import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';


const AboutPage: React.FC = () => {
    const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
    const title = 'Evolved - About'

    return (
        <div className='container'>
            <Helmet htmlAttributes={{ lang: 'en' }}>
                <title>{title}</title>
                {/* <link rel="icon" href={favicon} /> */}
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="article" />
                <meta property="og:image"
                    content={generalShare} />
                <meta property="og:url" content="https://hackathon.bio" />
                <meta name="twitter:card" content={metaDescription} />
                <meta property="og:description"
                    content={metaDescription} />
                <meta property="og:site_name" content={title} />
                <meta name="twitter:image:alt" content={title} />
                <meta name="twitter:site" content="@hackathon.bio" />
                <meta http-equiv="x-ua-compatible" content="ie=edge" />

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description"
                    content={metaDescription} />
                <link rel="stylesheet"
                    href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
            </Helmet>
            <div className="filter-controls">
                <h1>Building our Future</h1>
            </div>
            <div className='about-container'>
                <p>Evolved Technology is more than a community—it's a revolution in the making. Our mission is to hack the future of biology and AI/ML, uniting brilliant minds from labs, startups, and tech giants to create, collaborate, and disrupt.</p>
                <p>We believe in:
                    <ul>
                        <li>Open-source science</li>
                        <li>Democratizing biotech tools</li>
                        <li>Cross-pollination of ideas</li>
                        <li>Failing fast and iterating faster</li>
                    </ul>
                </p>
                <p>Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem where groundbreaking ideas become world-changing realities.</p>
                <p>Whether you're a PhD student, seasoned entrepreneur, or curious coder, Evolved Technology is your launchpad. Join us as we evolve the future of science itself.</p>
            </div>
            <div className='footer'></div>
        </div>
    );
};


export default AboutPage;
