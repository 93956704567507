import { AssetInterface } from '../reducers/types';
import { assetsImages } from '../assets';

const assets: AssetInterface[] = [
    {
        id: 0,
        title: 'A Catalog of Big Visions for Biology',
        url: 'https://www.sam-rodriques.com/post/a-catalog-of-big-visions-for-biology',
        description: `The biggest scientific advances of the 21st century were driven by visions of a new future for humanity. In the 1960s, the great dreamers in computer science were driven by ideas of computers that could think as humans, and it was their dreams that propelled us to the current progress in machine learning. The dream of unification has propelled physics forward since electricity and magnetism were first unified in the 1800s.`,
        type: 'Opinion'
    },
    {
        id: 1,
        title: 'The potential for Artificial Intelligence in Healthcare',
        image: 'https://www.nih.gov/sites/default/files/about-nih/2012-logo.png',
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6616181/',
        description: `The complexity and rise of Dataset in healthcare means that artificial intelligence (AI) will increasingly be applied within the field. Several types of AI are already being employed by payers and providers of care, and life sciences companies. The key categories of applications involve diagnosis and treatment recommendations, patient engagement and adherence, and administrative activities. Although there are many instances in which AI can perform healthcare tasks as well or better than humans, implementation factors will prevent large-scale automation of healthcare professional jobs for a considerable period. Ethical issues in the application of AI to healthcare are also discussed.`,
        type: 'Opinion'
    },
    {
        id: 2,
        title: 'Hugging Face for Health',
        image: 'https://ph-files.imgix.net/3ba557d8-6f8e-43a3-b827-25ef8098e2d6.jpeg?auto=format',
        url: 'https://huggingface.co/hf4h',
        description: `We curated a space of models trained on clinical and biomedical datasets (i.e Pubmed, Clinical Notes).`,
        type: 'Dataset'
    },
    {
        id: 3,
        title: 'The shaky Foundations of Foundation Models in Healthcare', url: 'https://hai.stanford.edu/news/shaky-foundations-foundation-models-healthcare',
        description: `Scholars detail the current state of large language models in healthcare and advocate for better evaluation frameworks.`,
        type: 'Opinion'
    },
    {
        id: 4,
        title: 'Perspectives from the brightest Minds', url: 'https://www.linkedin.com/posts/recursion-pharmaceuticals_techbio-biotech-drugdiscovery-ugcPost-7169015042334552064-FRx7/?utm_source=share&utm_medium=member_desktop',
        description: `To highlight our 10-K filing and annual report, we’ve compiled perspectives from the brightest minds in the #techbio industry - all of whom have contributed to our successful 2023, and the important work we’re continuing to do in 2024.`,
        type: 'News'
    },
    {
        id: 5,
        title: 'Decoding Protein Grammar with Machine Learning',
        url: '/assets',
        description: `How can we use machine learning to predict how sequence variations impact protein structure, function, and interactions?`,
        type: 'Challenge'
    },
    {
        id: 6,
        title: 'Predictive Models for Drug Efficacy and Safety',
        url: '/assets',
        description: `What are the current limitations in translating in vitro and animal model predictions to human outcomes, and how might we overcome these?`,
        type: 'Challenge'
    },
    {
        id: 7,
        title: 'AI Models for Predicting Protein Mutation Effects',
        url: '/assets',
        description: `How can we develop AI models that can predict the effects of protein mutations on their structure and function?`,
        type: 'Challenge'
    },
    // {
    //     id: 8,
    //     title: 'Automating Mass Spectrometry Dataset Annotation',

    //     url: '/assets',
    //     description: `Building an automated system for annotating mass spectrometry Dataset to enhance the accuracy and speed of protein identification and quantification.`,
    //     type: 'Challenge'
    // },
    {
        id: 9,
        title: 'Understanding Chromosomal Organization and Gene Regulation',
        url: '/assets',
        description: `How can we gain a deeper understanding of cellular processes by investigating chromosomal organization and its role in gene regulation?`,
        type: 'Challenge'
    },
    {
        id: 10,
        title: 'Reconstructing Protein Interaction Networks',
        url: '/assets',
        description: ` What computational approaches might help us infer indirect interactions or predict previously unknown interactions?`,
        type: 'Challenge'
    },
    {
        id: 11,
        title: 'Identifying Drug Repurposing Opportunities',
        url: '/assets',
        description: `What machine learning approaches might be most effective in identifying non-obvious connections between drugs and diseases?`,
        type: 'Challenge'
    },
    {
        id: 12,
        title: 'Fine-Tuning Foundation Models on Protein Families',
        url: '/assets',
        description: `What benefits and insights can we gain from fine-tuning foundation models like ESM3 and Evo on different protein families?`,
        type: 'Challenge'
    },
    {
        id: 13,
        title: 'Predicting Drug-Target Interactions with Multimodal Insights',
        url: '/assets',
        description: `How can we leverage insights from foundation models in different modalities, such as proteins and molecules, to predict drug-target interactions?`,
        type: 'Challenge'
    },
    {
        id: 14,
        title: 'Automating Experimental Design for Biologists',
        url: '/assets',
        description: `How can we use AI to automate and accelerate experimental design workflows, so that research efficiency and reproducibility is enhanced?`,
        type: 'Challenge'
    },
    {
        id: 15,
        title: 'ESM3',
        image: assetsImages['model-esm3'], url: 'https://github.com/evolutionaryscale/esm',
        description: `A frontier generative model for biology, able to jointly reason across three fundamental biological properties of proteins: sequence, structure, and function.`,
        type: 'Highlight',
        secondaryType: 'Model'
    },
    {
        id: 16,
        title: 'Evo',
        //image: assetsImages['model-evo'], 
        url: 'https://arcinstitute.org/news/blog/evo',
        description: `A biological foundation model capable of long-context modeling and design.`,
        type: 'Model'
    },
    {
        id: 17,
        title: 'scGPT',
        url: 'https://www.nature.com/articles/s41592-024-02201-0?fromPaywallRec=true',
        description: `scGPT is designed for single cell analysis.`,
        type: 'Model'
    },
    {
        id: 18,
        title: 'MoLFormer XL',
        //image: assetsImages['model-molformer'], 
        url: 'https://huggingface.co/ibm/MoLFormer-XL-both-10pct',
        description: `A pretrained AI model that infers the structure of molecules from simple representations, making it faster and easier to screen molecules for new applications or create them from scratch.`,
        type: 'Model'
    },
    {
        id: 19,
        title: '23andMe', url: 'https://www.biorxiv.org/content/10.1101/127241v3',
        description: `Features 2,280 public domain curated human genotypes.`,
        type: 'Dataset'
    },
    {
        id: 20,
        title: 'TargetFinder', url: 'https://github.com/shwhalen/targetfinder',
        description: `Features ~100,000 DNA-DNA interaction pairs, including gene targets of distal enhancers.`,
        type: 'Dataset'
    },
    {
        id: 21,
        title: 'HINT (High-quality INTeractomes)',
        url: '/https://hint.yulab.org/',
        description: `Features high-quality protein-protein interactions from 8 interactome resources ( BioGRID, MINT, iRefWeb, DIP, IntAct, HPRD, MIPS and the PDB).`,
        type: 'Dataset'
    },
    {
        id: 22,
        title: 'ProteinNet',
        url: 'https://github.com/aqlaboratory/proteinnet',
        description: `Features protein sequences, structures (secondary and tertiary), multiple sequence alignments (MSAs), position-specific scoring matrices (PSSMs), and standardized training / validation / test splits.`,
        type: 'Dataset'
    },
    {
        id: 23,
        title: 'Therapeutic Data Commons', url: 'https://tdcommons.ai/overview/',
        description: `Features data on therapeutic discovery, including target discovery, activity modeling, efficacy and safety, and manufacturing.`,
        type: 'Dataset'
    },
    {
        id: 24,
        title: 'Recursion', url: 'https://github.com/recursionpharma/rxrx-datasets',
        description: `Features morphological images and phenomics maps.`,
        type: 'Dataset'
    },
    // {
    //     id: 25,
    //     title: 'Zenodo',

    //     url: '/assets',
    //     description: `Christoph will upload composite of repos to Zenodo.`,
    //     type: 'Dataset'
    // },

    {
        id: 26,
        title: 'Bring Your Own Dataset',
        url: '/assets',
        description: `Bring your own dataset to the competition!`,
        type: 'Dataset'
    }, {
        id: 27,
        title: 'SMI-TED',
        url: 'https://github.com/IBM/materials/tree/main/smi-ted',
        description: `A large encoder-decoder chemical foundation model, SMILES-based Transformer Encoder-Decoder (SMI-TED), pre-trained on a curated dataset of 91 million SMILES samples sourced from PubChem, equivalent to 4 billion molecular tokens. `,
        type: 'Model'
    },
    {
        id: 28,
        title: 'Polaris',
        url: 'https://polarishub.io/',
        image: assetsImages['dataset-polaris'],
        description: `Features over 80 small molecule datasets and benchmarks for the ML in drug discovery community.`,
        type: 'Highlight',
        secondaryType: 'Dataset'
    },
    {
        id: 29,
        title: 'Enveda',
        url: '/challenge/enveda-2024',
        image: assetsImages['challenge-enveda-2024'],
        description: `Leverage our data to tackle one of two exciting challenges for a chance to win $10,000! Click to learn more.`,
        type: 'Highlight',
        secondaryType: 'Dataset'
    }]


export default assets;