import React, { useState } from 'react';
import { PeopleInterface } from '../../reducers/types';
import './PhotoGrid.css'
import { useInView } from '../../utils/fadeIn';

interface PhotoGridItemProps {
    person: PeopleInterface;
}

const PhotoGridItem: React.FC<PhotoGridItemProps> = ({ person }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [ref, isInView] = useInView({ threshold: 0.1 });

    return (
        <div className="col-lg-3 col-md-4 mb-3" ref={ref}
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        // onClick={() => setIsHovered(!isHovered)}
        >
            <div className={`card photo-card h-100 ${isInView ? 'fade-in' : ''}`} >
                <div className="card-body text-center">
                    {!isHovered && (
                        <>
                            <img src={person.image} alt={person.name} className="img-fluid rounded mb-3" />
                            <h5 className="card-title photo-card-text">{person.name}</h5>
                            <p className="card-text photo-card-text" >{person.affiliation}</p>
                        </>
                    )}
                    {isHovered && (<>
                        <h5 className="card-title photo-card-text">{person.name}</h5>
                        <p className="card-text photo-card-text">{person.bio}</p>
                    </>
                    )}
                </div>
            </div>
        </div>
    );
};

interface peopleList {
    people: PeopleInterface[];
}

const PhotoGrid: React.FC<peopleList> = ({ people }) => {
    return (
        <div className="container mt-4">
            <div className="row">
                {people.map(person => (
                    <PhotoGridItem key={person.id} person={person} />
                ))}
            </div>
        </div>
    );
};

export default PhotoGrid;