// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button:not(.collapsed) {
    background: var(--evolved-earth) !important;
    color: var(--evolved-white) !important;
    box-shadow: 0 0 0 0.25rem var(--evolved-earth-shadow) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Event/Faq.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,sCAAsC;IACtC,gEAAgE;AACpE","sourcesContent":[".accordion-button:not(.collapsed) {\n    background: var(--evolved-earth) !important;\n    color: var(--evolved-white) !important;\n    box-shadow: 0 0 0 0.25rem var(--evolved-earth-shadow) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
