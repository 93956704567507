import { PrizeInterface, PrizeCategoryInterface } from '../reducers/types';
import companies from './companies';

const prizes: PrizeInterface[] = [
    // {
    //     id: "1",
    //     name: '$5K Cash Prize & Partnership Meeting',
    //     sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    // }, {
    //     id: "2",
    //     name: 'Feature in "Weekly Dose of Optimism" newsletter',
    //     sponsor: companies.filter(company => ['Not Boring'].includes(company.name))[0],
    // }, {
    //     id: "3",
    //     name: '$2.5K credits & GPT-4 + plug-in access',
    //     sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    // }, {
    //     id: "4",
    //     name: '$3K LatchBio credits',
    //     sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    // }, {
    //     id: "5",
    //     name: '2 Tickets to Nucleate Summit',
    //     sponsor: companies.filter(company => ['Nucleate'].includes(company.name))[0],
    // }, {
    //     id: "6",
    //     name: '"Biodesigned" Collagen Goodie Box',
    //     sponsor: companies.filter(company => ['Geltor'].includes(company.name))[0],
    // }, {
    //     id: "7",
    //     name: '$1K in AWS Credits',
    //     sponsor: companies.filter(company => ['Compound'].includes(company.name))[0],
    // }, {
    //     id: "8",
    //     name: '$5K compute & $50 merch voucher per team member',
    //     sponsor: companies.filter(company => ['Hugging Face'].includes(company.name))[0],
    // }
    // , {
    //     id: "9",
    //     name: 'Performance Prebiotics & Protein Packages',
    //     sponsor: companies.filter(company => ['Fitbiomics'].includes(company.name))[0],
    // }, {
    //     id: "10",
    //     name: '2 Tickets to neurIPS',
    //     sponsor: companies.filter(company => ['KdT Ventures'].includes(company.name))[0],
    // }, {
    //     id: "11",
    //     name: '2 Tickets to SynBioBeta 2024',
    //     sponsor: companies.filter(company => ['SynBioBeta'].includes(company.name))[0],
    // }
    // , {
    //     id: '12',
    //     name: '$3K Cash Prize',
    //     sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    // }, {
    //     id: '14',
    //     name: 'GPT-4 + plug-in access',
    //     sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    // }, {
    //     id: '15',
    //     name: '$2K LatchBio credits',
    //     sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    // }, {
    //     id: '16',
    //     name: '$2K Cash Prize',
    //     sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    // }, {
    //     id: '17',
    //     name: '$1K LatchBio credits',
    //     sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    // }, {
    //     id: '18',
    //     name: 'Codon Digest will feature 3 teams from the hackathon in the next newsletter',
    //     sponsor: companies.filter(company => ['Asimov Press'].includes(company.name))[0],
    // }, {
    //     id: '19',
    //     name: 'Signed David Goodsell Book & Framed Illustration',
    //     sponsor: companies.filter(company => ['Fifty Years'].includes(company.name))[0],
    // }, 
    {
        id: "1",
        name: '$5K in Cash',
        sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    }, {
        id: "2",
        name: 'Newsletter Feature',
        sponsor: companies.filter(company => ['Not Boring'].includes(company.name))[0],
    }, {
        id: "3",
        name: '$2.5K in Credits',
        sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    }, {
        id: "4",
        name: '$3K in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: "5",
        name: '2 Summit Tickets',
        sponsor: companies.filter(company => ['Nucleate'].includes(company.name))[0],
    }, {
        id: "6",
        name: 'Goodie Box',
        sponsor: companies.filter(company => ['Geltor'].includes(company.name))[0],
    }, {
        id: "7",
        name: '$1K in Credits',
        sponsor: companies.filter(company => ['Compound'].includes(company.name))[0],
    }, {
        id: "8",
        name: '$5K in Credits',
        sponsor: companies.filter(company => ['Hugging Face'].includes(company.name))[0],
    }
    , {
        id: "9",
        name: 'Protein Packages',
        sponsor: companies.filter(company => ['Fitbiomics'].includes(company.name))[0],
    }, {
        id: "10",
        name: '2 neurIPS Tickets',
        sponsor: companies.filter(company => ['KdT Ventures'].includes(company.name))[0],
    }, {
        id: "11",
        name: '2 Tickets',
        sponsor: companies.filter(company => ['SynBioBeta'].includes(company.name))[0],
    }
    , {
        id: '12',
        name: '$3K in Cash',
        sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    }, {
        id: '14',
        name: 'GPT-4 Access',
        sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    }, {
        id: '15',
        name: '$2K in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: '16',
        name: '$2K in Cash',
        sponsor: companies.filter(company => ['Lux Capital'].includes(company.name))[0],
    }, {
        id: '17',
        name: '$1K in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: '18',
        name: 'Newsletter Feature',
        sponsor: companies.filter(company => ['Asimov Press'].includes(company.name))[0],
    }, {
        id: '19',
        name: 'Signed Goodsell Book',
        sponsor: companies.filter(company => ['Fifty Years'].includes(company.name))[0],
    },

    {
        id: '20',
        name: '$25k in Credits',
        sponsor: companies.filter(company => ['AWS'].includes(company.name))[0],
    }, {
        id: '21',
        name: '$10k in Credits',
        sponsor: companies.filter(company => ['AWS'].includes(company.name))[0],
    }
    ,
    // {
    //     id: '22',
    //     name: '$5k in Credits',
    //     sponsor: companies.filter(company => ['AWS'].includes(company.name))[0],
    // }
    // , {
    //     id: '23',
    //     name: '$5k in Credits',
    //     sponsor: companies.filter(company => ['AWS'].includes(company.name))[0],
    // }
    {
        id: '24',
        name: '$3k in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: '25',
        name: '$2k in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: '26',
        name: '$1k in Credits',
        sponsor: companies.filter(company => ['LatchBio'].includes(company.name))[0],
    }, {
        id: '27',
        name: '$1k in Credits',
        sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    }, {
        id: '28',
        name: '$2.5k in Credits',
        sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    }, {
        id: '29',
        name: '$5k in Credits',
        sponsor: companies.filter(company => ['OpenAI'].includes(company.name))[0],
    }
    , {
        id: '30',
        name: '100k Credits',
        sponsor: companies.filter(company => ['EvolutionaryScale'].includes(company.name))[0],
    }
    , {
        id: '31',
        name: 'Present to Team',
        sponsor: companies.filter(company => ['EvolutionaryScale'].includes(company.name))[0],
    }, {
        id: '32',
        name: '$10k in Cash',
        sponsor: companies.filter(company => ['Enveda Biosciences'].includes(company.name))[0],
    }]

const prizesCategories: PrizeCategoryInterface[] = [
    {
        id: "1",
        name: 'First Place',
        prizes: prizes.filter(prize => ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].includes(prize.id)),
        emoji: '🥇'
    }, {
        id: "2",
        name: 'Second Place',
        prizes: prizes.filter(prize => ['12', '6', '14', '15'].includes(prize.id)),
        emoji: '🥈'
    }, {
        id: "3",
        name: 'Third Place',
        prizes: prizes.filter(prize => ['16', '13', '14', '17'].includes(prize.id)),
        emoji: '🥉'
    }, {
        id: "4",
        name: 'Codon Prize',
        prizes: prizes.filter(prize => ['18'].includes(prize.id)),
        emoji: '🏆'
    }, {
        id: "5",
        name: 'Impact Prize',
        prizes: prizes.filter(prize => ['19'].includes(prize.id)),
        emoji: '🏆'
    }, {
        id: "6",
        name: 'First Place',
        prizes: prizes.filter(prize => ['20', '24', '29', '30'].includes(prize.id)),
        emoji: '🥇'
    }, {
        id: "7",
        name: 'Second Place',
        prizes: prizes.filter(prize => ['21', '25', '28', '30'].includes(prize.id)),
        emoji: '🥈'
    }, {
        id: "8",
        name: 'Third Place',
        prizes: prizes.filter(prize => ['21', '26', '27', '30'].includes(prize.id)),
        emoji: '🥉'
    }
    // , {
    //     id: "9",
    //     name: 'Finalists',
    //     prizes: prizes.filter(prize => ['23'].includes(prize.id)),
    //     emoji: '🏅'
    // }
    , {
        id: "10",
        name: 'ESM Creativity Prize',
        prizes: prizes.filter(prize => ['31'].includes(prize.id)),
        emoji: '✨'
    }
    , {
        id: "11",
        name: 'Enveda Seedling Prize',
        prizes: prizes.filter(prize => ['32'].includes(prize.id)),
        emoji: '🌱'
    }
]


export default prizesCategories;