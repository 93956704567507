import { useEffect, useRef, useState, MutableRefObject } from 'react';

interface UseInViewOptions extends IntersectionObserverInit { }

export const useInView = (options: UseInViewOptions): [MutableRefObject<HTMLDivElement | null>, boolean] => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsInView(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, options]);

    return [ref, isInView];
};

