import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import eventReducer, {EventState} from './reducers/eventReducer';
import projectReducer, {ProjectState} from './reducers/projectReducer';
import { createStore, combineReducers } from 'redux';


const rootReducer = combineReducers({
  event: eventReducer,
  project: projectReducer
});

export interface AppStateInterface {
  event: EventState;
  project: ProjectState;
}

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
