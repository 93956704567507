import { EventInterface } from './types';
// import { AppStateInterface } from '../index';
import { ActionTypes } from '../actions'
import Events from '../data/events';

export interface EventState {
  events: EventInterface[];
}

const initialState: EventState = {
  events: [...Events],
};

const eventReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default eventReducer;