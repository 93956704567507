import React, { useState } from 'react';
import { EventInterface } from '../../reducers/types';
import { useSelector } from 'react-redux';
import { eventsSelector } from '../../selectors/selectors';
import { useNavigate } from 'react-router-dom';
import "./Events.css";
import { Helmet } from 'react-helmet';
import generalShare from '../../assets/main/general-share.webp';


const Events: React.FC = () => {

  const navigate = useNavigate();
  const events = useSelector(eventsSelector);

  const playVideo = (event: EventInterface) => {
    if (event.mainVideo) {
      const video = document.getElementById(`video_${event.id}`) as HTMLVideoElement;
      video.play();
    }
  }
  const stopVideo = (event: EventInterface) => {
    if (event.mainVideo) {
      const video = document.getElementById(`video_${event.id}`) as HTMLVideoElement;
      video.pause();
    }
  }

  const [filteredEvents, setFilteredEvents] = useState(events);
  // const [selectedEvent, setSelectedEvent] = useState('all');
  const [activeCategory, setActiveCategory] = useState('all');


  const search = (selectedType_: string) => {
    setFilteredEvents(events.filter((event: EventInterface) => {
      setActiveCategory(selectedType_)
      const eventUpcoming = event.upcoming;
      const eventMatch = (selectedType_ === 'all'
        || (eventUpcoming === false && selectedType_ === 'Past')
        || (eventUpcoming === true && selectedType_ === 'Upcoming'));
      return eventMatch;
    }));
  }

  const eventTypes = Array.from(new Set(events.map(event => event.upcoming ? 'Past' : 'Upcoming')));
  const metaDescription = `Evolved Technology is more than a community—it's a revolution in the making. Through hackathons, meetups, and workshops, we're building a decentralized innovation ecosystem.`
  const title = 'Evolved - Events'

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{title}</title>
        {/* <link rel="icon" href={favicon} /> */}
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:image"
          content={generalShare} />
        <meta property="og:url" content="https://hackathon.bio" />
        <meta name="twitter:card" content={metaDescription} />
        <meta property="og:description"
          content={metaDescription} />
        <meta property="og:site_name" content={title} />
        <meta name="twitter:image:alt" content={title} />
        <meta name="twitter:site" content="@hackathon.bio" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description"
          content={metaDescription} />
        <link rel="stylesheet"
          href="    https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />
      </Helmet>

      <div className="title-section container">
        <h1>Browse Events</h1>
      </div>

      <div className='title-section'>

        <div className='secondary-navbar container' style={{ borderBottom: '2px solid grey', marginBottom: '20px' }}>
          <div className={activeCategory === 'all' ? 'events-selector active' : 'events-selector'} onClick={() => search('all')}>All</div>
          {eventTypes.map((eventType) => (
            <div className={activeCategory === eventType ? 'events-selector active' : 'events-selector'} onClick={() => search(eventType)}>{eventType}</div>
          ))}

        </div>
      </div>

      <div className="container" >
        <div className="row">
          {filteredEvents.map((event: EventInterface) => (
            <div key={event.id} className="col-md-4 fade-in">
              <div className="h-100 text-center overview-card" onMouseEnter={() => playVideo(event)} onMouseLeave={() => stopVideo(event)} onClick={() => navigate(`/event/${event.id}`)}>
                {/* <div className="card h-100 text-center overview-card" onMouseEnter={() => playVideo(event)} onMouseLeave={() => stopVideo(event)} onClick={() => navigate(`/event/${event.id}`)}> */}
                <div className='events-image-wrapper'>
                  {event.mainVideo ? <video id={'video_' + event.id} className='events-image' loop src={event.mainVideo} />
                    : <img src={event.mainImage} alt={event.title} className='events-image' />}
                </div>
                <h2>{event.title}</h2>
                <p>{event.homeBlurb}</p>
                <div className='event-badge'>{event.upcoming ? 'Upcoming' : 'Past'}</div>
                <div className='event-badge' style={{
                  marginLeft: '10px',
                  // background: 'var(--evolved-earth)',
                  // color: 'white'
                  background: 'unset',
                  border: '1px solid black'
                }}>{event.location}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Events;

