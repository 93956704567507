import { ProjectInterface } from './types';
// import { AppStateInterface } from '../index';
import { ActionTypes } from '../actions'
import Projects from '../data/projects';

export interface ProjectState {
  projects: ProjectInterface[];
}

const initialState: ProjectState = {
  projects: [...Projects],
};

const projectReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default projectReducer;