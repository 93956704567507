import React, { useEffect } from 'react';
import "./Timeline.css";
import hamburgerIcon from '../../assets/icons/hamburger-icon.svg';
import { icons } from '../../assets';

const Timeline: React.FC = () => {

    const setEqualHeights = () => {
        const setMaxHeight = (selector: string) => {
            const elements = document.querySelectorAll(selector);
            let maxHeight = 0;

            // Reset height to auto to get the natural height
            elements.forEach((element) => {
                (element as HTMLElement).style.height = 'auto';
            });

            // Calculate the max height
            elements.forEach((element) => {
                const elementHeight = (element as HTMLElement).offsetHeight;
                if (elementHeight > maxHeight) {
                    maxHeight = elementHeight;
                }
            });

            // Set all elements to the max height
            elements.forEach((element) => {
                (element as HTMLElement).style.height = `${maxHeight}px`;
            });
        };

        // Set equal heights for each group of elements
        setMaxHeight('.timeline-content');
        setMaxHeight('.timeline-title');
        setMaxHeight('.date');
    };


    const correctMargins = () => {
        const elements = document.querySelectorAll<HTMLElement>('.col-lg-2.col-md-4.col-sm-6.mx-auto.timeline-item');
        elements.forEach((element) => {
            const computedStyle = window.getComputedStyle(element);
            const marginLeft = parseFloat(computedStyle.marginLeft) * 2;
            const newMarginLeft = `${-marginLeft}px`;

            // console.log(`Margins for element:  
            //     Left: ${marginLeft}
            //     newLeft: ${newMarginLeft}`);


            const timelineContent = element.querySelector<HTMLElement>('.timeline-container');
            if (timelineContent) {
                timelineContent.style.marginLeft = newMarginLeft;
            }
        });

    };

    useEffect(() => {
        // Set equal heights on component mount
        setEqualHeights();
        correctMargins();

        // Set equal heights on window resize
        window.addEventListener('resize', setEqualHeights);
        window.addEventListener('resize', correctMargins);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', setEqualHeights);
            window.removeEventListener('resize', correctMargins);
        };
    }, []);

    return (
        <>
            <div className="container py-5">
                <div className="row text-center text-md-left">
                    <div className="col-lg-2 col-md-4 col-sm-6 mx-auto timeline-item">
                        <div className='timeline-container'>
                            <h3 className='timeline-step'>01.</h3>
                            <h3 className='timeline-title'>Form Teams</h3>
                            <p className="date">By Sept 22</p>
                            <div className="timeline-content">
                                <img src={icons['team']} width='30px' alt="Team icon" className="img-fluid" />
                                <div>
                                    <p>5-15 per team</p>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <img src={icons['discord']} width='30px' alt="Discord icon" className="img-fluid" />
                                <div>
                                    <p>Join Discord to brainstorm, build teams</p>
                                </div>
                            </div>
                        </div>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mx-auto timeline-item">
                        <div className='timeline-container'>
                            <h3 className='timeline-step'>02.</h3>
                            <h3 className='timeline-title'>Apply</h3>
                            <p className="date">By Sept 22</p>
                            <div className="timeline-content">
                                <img src={icons['upload']} width='30px' alt="Upload icon" className="img-fluid" />
                                <div>
                                    <p>Submit one official project proposal per team</p>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <img src={icons['checkmark']} width='30px' alt="Checkmark icon" className="img-fluid" />
                                <div>
                                    <p>Top 10-20 selected teams (finalists) will hack</p>
                                </div>
                            </div>
                        </div>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mx-auto timeline-item" >
                        <div className='timeline-container'>
                            <h3 className='timeline-step'>03.</h3>
                            <h3 className='timeline-title'>Build Projects</h3>
                            <p className="date">Oct 10-20</p>
                            {/* <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Build icon" className="img-fluid" />
                            <div>
                                <p>Start building your projects</p>
                            </div>
                        </div>
                        <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Build icon" className="img-fluid" />
                            <div>
                                <p>Work with your team to complete the project</p>
                            </div>
                        </div> */}
                        </div>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mx-auto timeline-item" >
                        <div className='timeline-container'>
                            <h3 className='timeline-step'>04.</h3>
                            <h3 className='timeline-title'>Submit Demos</h3>
                            <p className="date">Oct 20</p>
                            {/* <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Submit icon" className="img-fluid" />
                            <div>
                                <p>Submit your demos online</p>
                            </div>
                        </div>
                        <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Submit icon" className="img-fluid" />
                            <div>
                                <p>Ensure all team members participate</p>
                            </div>
                        </div> */}
                        </div>
                        <div className="vertical-line"></div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mx-auto timeline-item" >
                        <div className='timeline-container'>
                            <h3 className='timeline-step'>05.</h3>
                            <h3 className='timeline-title'>Final live Pitch</h3>
                            <p className="date">Oct 20, Zoom</p>
                            {/* <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Final pitch icon" className="img-fluid" />
                            <div>
                                <p>Prepare for the final pitch</p>
                            </div>
                        </div>
                        <div className="timeline-content">
                            <img src={hamburgerIcon} alt="Final pitch icon" className="img-fluid" />
                            <div>
                                <p>Join the live pitch on Zoom</p>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Timeline;
