// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

input[type="text"],
select {
    padding: 10px;
    border: none;
    border-radius: 5px;
}


.event-badge {
    box-sizing: border-box;
    color: var(--event-badge-foreground);
    background: var(--evolved-green);
    border: solid 2px var(--evolved-green);
    padding: 4px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 10px;
}

.event-badge-secondary {
    box-sizing: border-box;
    color: var(--event-foreground);
    border: solid 2px var(--evolved-green);
    padding: 3px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.filter-controls {
    display: block;
    justify-content: center;
    gap: 10px;
    text-align: center;
    margin-bottom: 100px;
}

.filter-title {
    margin-bottom: 20px;
}

.project-card img {
    height: 50% !important;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Projects/Projects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI,sBAAsB;IACtB,oCAAoC;IACpC,gCAAgC;IAChC,sCAAsC;IACtC,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,8BAA8B;IAC9B,sCAAsC;IACtC,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":[".search-bar {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 10px;\n}\n\ninput[type=\"text\"],\nselect {\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n}\n\n\n.event-badge {\n    box-sizing: border-box;\n    color: var(--event-badge-foreground);\n    background: var(--evolved-green);\n    border: solid 2px var(--evolved-green);\n    padding: 4px;\n    display: inline-block;\n    border-radius: 5px;\n    margin-bottom: 10px;\n}\n\n.event-badge-secondary {\n    box-sizing: border-box;\n    color: var(--event-foreground);\n    border: solid 2px var(--evolved-green);\n    padding: 3px;\n    display: inline-block;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    margin-left: 10px;\n}\n\n.filter-controls {\n    display: block;\n    justify-content: center;\n    gap: 10px;\n    text-align: center;\n    margin-bottom: 100px;\n}\n\n.filter-title {\n    margin-bottom: 20px;\n}\n\n.project-card img {\n    height: 50% !important;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
